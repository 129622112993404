import { i18n } from 'methone/services/i18n'

export const MENU_AREA = 'vehicle-broker'

export const MENU_PATHS = {
  VEHICLES: `vehicles`,
  TYPES: `types`,
  MODELS: `brand-models`
}

export const docTypes: Options = [
  { value: 'crlve', label: 'CRLV-e' },
  { value: 'crv', label: 'CRV' },
  { value: 'outros', label: 'Outros' }
]

export const vehicleStatus: Options<string> = [
  {
    value: 'true',
    get label() {
      return i18n('Active')
    }
  },
  {
    value: 'false',
    get label() {
      return i18n('Inactive')
    }
  }
]
