import { Container } from 'react-bootstrap'

import styled from 'styled-components'

function handleStatusColor(status: boolean): string {
  switch (String(status)) {
    case 'false':
      return 'var(--red-500)'
    case 'true':
      return 'var(--green-500)'
    default:
      return 'var(--gray-300)'
  }
}

export const SolidDot = styled.div<{ status: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ status }) => handleStatusColor(status)};
`

export const Form = styled.form`
  width: 100%;
  display: grid;
  gap: var(--spacing-3);
  grid-template-areas:
    'CLT CLT CLT CLT CGP'
    'PLT PED DTP RRV RRV'
    'TOD TOD TOD TOD TOD'
    'ACT ACT ACT ACT ACT';
  grid-template-columns: 100px 200px 200px 1fr 200px;

  > .form-select-container--client {
    grid-area: CLT;
  }

  > .form-select-container--groups {
    grid-area: CGP;
  }

  > .form-input-container--plate {
    grid-area: PLT;
  }

  > .form-select-container--platesEnd {
    grid-area: PED;
  }

  > .form-select-container--docTypes {
    grid-area: DTP;
  }

  > .renavam-crv-container {
    grid-area: RRV;
    display: grid;
    grid-template-areas: 'RNV CRV';
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-2);

    > .form-input-container--renavam {
      grid-area: RNV;
    }

    > .form-input-container--crv {
      grid-area: CRV;
    }
  }

  > .types-model-container {
    grid-area: TOD;
    display: grid;
    grid-template-areas: 'TIP MOD STS';
    grid-template-columns: 200px 1fr 200px;
    gap: var(--spacing-2);

    > .form-select-container--types {
      grid-area: TIP;
    }

    > .form-input-container--model {
      grid-area: MOD;
    }

    > .form-select-container--status {
      grid-area: STS;
    }
  }

  > .actions {
    grid-area: ACT;
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-2);

    > div.dropdown {
      margin-right: auto;
    }

    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      gap: var(--spacing-2);
    }
  }
`

export const VehiclesContainer = styled(Container)`
  padding: var(--spacing-4);

  .card {
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-2);

      > button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        gap: var(--spacing-2);

        > .badge {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  > div {
    > table {
      .table-column-client,
      .table-column-plateView {
        > div {
          display: flex;
          gap: var(--spacing-2);

          > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;

            &.badge {
              height: 21px;
            }
          }
        }
      }
    }
  }
`
