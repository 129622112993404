export const ptBR: I18NProps = {
  name: 'Português (Brasil)',
  code: 'pt-BR',
  values: {
    'Vehicle broker': 'Despachante',
    Vehicles: 'Veículos',
    'Withdrawal protocol': 'Protocolo de baixa',
    Plate: 'Placa',
    'Doc type': 'Tipo de documento',
    Withdrawal: 'Baixa',
    'Delete vehicle': 'Excluir veículo',
    'Disable vehicle': 'Desativar veículo',
    'An error occurred while opening the file window.': 'Ocorreu um erro ao abrir a janela de arquivos.',
    'Are you sure you want to delete the vehicle "{key}"?': 'Tem certeza que deseja excluir o veículo "{key}"?',
    'Are you sure you want to disable the vehicle "{key}"?': 'Tem certeza que deseja desativar o veículo "{key}"?',
    'Are you sure you want to delete the type "{type}"?': 'Tem certeza que deseja excluir o tipo "{type}"?',
    'Are you sure you want to delete the brand/model "{key}"?': 'Tem certeza que deseja excluir a marca/modelo "{key}"?', // eslint-disable-line prettier/prettier
    'Brand/Model': 'Marca/Modelo',
    'Brand/Models': 'Marcas/Modelos',
    'New type': 'Novo tipo',
    'New brand/model': 'Nova marca/modelo',
    'New vehicle': 'Novo veículo',
    Withdraw: 'Baixar',
    Reactive: 'Reativar',
    'Plate end': 'Final da placa',
    'To complete the vehicle withdraw, fill out and submit the document withdrawal protocol or the ATPV-e.': 'Para finalizar a baixa do veículo, preencha e envie o protocolo de retirada de documentos ou o ATPV-e.', // eslint-disable-line prettier/prettier
    'Download the protocol': 'Baixar o protocolo',
    "I'll send the ATPV-e": 'Vou enviar o ATPV-e',
    'Get withdrawal protocol': 'Baixar o protocolo de retirada',
    'Loading...': 'Carregando...',
    Ready: 'Pronto'
  }
}
