import React from 'react'
import Dropzone, { DropEvent, DropzoneProps } from 'react-dropzone'

import cx from 'classnames'

import { i18n } from 'methone/services/i18n'

import { DropMessage, FileItemContainer, FileItemWrapper } from './styled'

export interface FileData {
  id?: string
  name: string
  extra: string | number | JSX.Element
  preview?: string | FAIconType
}

interface Props {
  fileData: FileData
  idleMessage: string
  onClick: (data: FileData) => void
  onDelete: (data: FileData) => void
  onDropAccepted: <T extends File>(files: T[], event: DropEvent) => void
  showDelete: boolean
  dropzone?: DropzoneProps
}

function faIconTypeGuard(arg: any): arg is FAIconType {
  const allowedKeys = ['fas', 'far', 'fal', 'fat', 'fad', 'fas', 'far', 'fa-light', 'fa-thin', 'fa-duotone']

  return typeof arg === 'string' && allowedKeys.some((key) => arg.startsWith(key))
}

export const FileItem: React.FC<Props> = ({
  fileData,
  idleMessage,
  onClick,
  onDelete,
  onDropAccepted,
  showDelete,
  dropzone
}) => {
  function handleMessage(isDragAccept: boolean, isDragActive: boolean, isDragReject: boolean): string {
    if (isDragActive && isDragAccept) {
      return i18n('Drop the file here.')
    }

    if (isDragActive && isDragReject) {
      return i18n('Unsupported file type.')
    }

    return idleMessage ?? i18n('Click here or drop a file to upload.')
  }

  return (
    <FileItemWrapper>
      <Dropzone
        accept={{ 'application/pdf': ['.pdf'] }}
        maxFiles={1}
        onDropAccepted={onDropAccepted}
        noClick={!!fileData}
        {...dropzone}
      >
        {({ getInputProps, getRootProps, isDragAccept, isDragActive, isDragReject }) => (
          <FileItemContainer
            className={cx('file-item', { missing: !fileData })}
            {...getRootProps({ onClick: fileData ? () => onClick(fileData) : undefined })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          >
            {fileData && (
              <>
                <div className="file-item__icon">
                  {faIconTypeGuard(fileData.preview) ? (
                    <i className={fileData.preview} />
                  ) : typeof fileData.preview === 'string' ? (
                    <img src={fileData.preview} alt={fileData.name} />
                  ) : (
                    <i className="far fa-file-pdf fa-2xl" />
                  )}
                </div>
                <div className="file-item__details">
                  <div className="file-item__name">{fileData.name}</div>
                  <div className="file-item__extra">{fileData.extra}</div>
                </div>
              </>
            )}

            {(!fileData || isDragAccept || isDragReject) && (
              <DropMessage isDragAccept={isDragAccept} isDragReject={isDragReject}>
                <span>{handleMessage(isDragAccept, isDragActive, isDragReject)}</span>
              </DropMessage>
            )}
            <input type="file" {...getInputProps()} />
          </FileItemContainer>
        )}
      </Dropzone>
      {fileData && showDelete && (
        <div className="file-item__delete">
          <button type="button" onClick={() => onDelete(fileData)}>
            <i className="fa-regular fa-trash-can" />
          </button>
        </div>
      )}
    </FileItemWrapper>
  )
}
