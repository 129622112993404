import { transparentize } from 'polished'
import styled, { DefaultTheme } from 'styled-components'

interface Props {
  isDragAccept: boolean
  isDragReject: boolean
}

function handleBackground({ theme, isDragAccept, isDragReject }: Props & { theme: DefaultTheme }): string {
  return transparentize(0.8, isDragAccept ? theme['green-500'] : isDragReject ? theme['red-500'] : theme['gray-500'])
}

export const DropMessage = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background: ${({ theme, isDragAccept, isDragReject }) => handleBackground({ theme, isDragAccept, isDragReject })};

  span {
    font-family: var(--font-semibold);
  }
`

export const FileItemWrapper = styled.div`
  position: relative;

  > .file-item__delete {
    position: absolute;
    top: 50%;
    right: var(--spacing-4);
    transform: translateY(-50%);

    > button {
      background: none;
      border: none;
      padding: var(--spacing-1) var(--spacing-2);
      border-radius: var(--spacing-1);

      &:hover {
        background: var(--gray-700);
      }

      i,
      svg > path {
        color: var(--red-500);
      }
    }
  }

  &:not(:hover) {
    > .file-item__delete {
      display: none;
    }
  }
`

export const FileItemContainer = styled.div<Props>`
  cursor: pointer;
  position: relative;
  border-radius: var(--spacing-2);
  background: var(--gray-500);
  padding: var(--spacing-2);
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-2);
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px dashed
      ${({ isDragAccept, isDragReject }) =>
        isDragAccept ? 'var(--green-500)' : isDragReject ? 'var(--red-500)' : 'var(--gray-1000)'};
    border-radius: var(--spacing-2);
    cursor: pointer;
    content: ${({ isDragAccept, isDragReject }) => (isDragAccept || isDragReject ? '""' : 'none')};
  }

  &.missing {
    opacity: 0.8;
    transition: opacity 200ms ease;

    :hover {
      opacity: 1;
    }

    &::after {
      content: '';
    }
  }

  > .file-item__icon {
    height: 45px;
    width: 45px;
    background: var(--gray-700);
    border-radius: var(--spacing-1);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      height: 45px;
      width: 45px;
    }
  }

  > .file-item__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > .file-item__name {
      font-family: var(--font-semibold);
    }

    > .file-item__extra {
      font-size: 9px;
      opacity: 0.8;
    }
  }
`
