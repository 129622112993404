import React from 'react'

import { LockedContainer } from './styled'

export const Locked: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  return (
    <LockedContainer {...props}>
      <i className="fas fa-lock" />
    </LockedContainer>
  )
}
