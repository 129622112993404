import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'

type BasicEntity = { id: string; name: string }

function paginationGuard(item: any): item is PaginationDTO<BasicEntity> {
  return !Array.isArray(item) && 'entries' in item
}

function stringNumberArrayGuard(item: any): item is string[] {
  return Array.isArray(item) && item.every((i) => typeof i === 'string')
}

export function toOption(item: string[]): Options
export function toOption(item: BasicEntity[]): Options
export function toOption(item: PaginationDTO<BasicEntity>): Options
export function toOption(item: BasicEntity[] | PaginationDTO<BasicEntity> | string[]): Options {
  if (paginationGuard(item)) {
    return item.entries.map((e) => ({ value: e.id, label: e.name })).sort((a, b) => a.label.localeCompare(b.label))
  }

  if (stringNumberArrayGuard(item)) {
    return item.map((e) => ({ value: e, label: e })).sort((a, b) => a.label.localeCompare(b.label))
  }

  return item.map((e) => ({ value: e.id, label: e.name })).sort((a, b) => a.label.localeCompare(b.label))
}
