import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput } from 'methone/components/ui/Forms'
import { i18n } from 'methone/services/i18n'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import * as vehicleModelServices from 'plugin-vehicles/services/vehicleModels'
import { VehicleModelResponseDTO } from 'plugin-vehicles/shared/dto/VehicleModelResponseDTO'
import { VehicleModelUpdateDTO } from 'plugin-vehicles/shared/dto/VehicleModelUpdateDTO'
import { MENU_AREA, MENU_PATHS } from 'plugin-vehicles/utils/constants'

import { EditorContainer, EditorFieldset } from './styled'

type FormProps = VehicleModelResponseDTO

const defaultValues: FormProps = {
  id: null,
  name: ''
}

export function VehicleModelsEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset } = useForm<FormProps>({ defaultValues, mode: 'all' })

  const [isLoading, setIsLoading] = React.useState(true)

  const navigate = useNavigate()

  async function handleGetVehicleData(): Promise<void> {
    try {
      setIsLoading(true)

      if (id === 'new') {
        reset(defaultValues)
      } else {
        const response = await vehicleModelServices.findVehicleModel(id)
        reset(response)
      }

      setIsLoading(false)
    } catch (e) {
      handleHTTPRequestError(e)
    }
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setIsLoading(true)

      const bodyData: VehicleModelUpdateDTO = { name: data.name }

      if (id === 'new') {
        const createdUser = await vehicleModelServices.createVehicleModel(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.MODELS}/${createdUser.id}`)
      } else {
        await vehicleModelServices.updateVehicleModel(id, bodyData)
        await handleGetVehicleData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (id) {
      handleGetVehicleData()
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={isLoading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <HookFormInput control={control} rules={{ required: true }} name="name" label={i18n('Name')} />
              </Card.Body>
            </Card>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.MODELS}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
    </>
  )
}
