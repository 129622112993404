export const PLUGIN_NAME = 'plugin-vehicles'
export enum REST_ENDPOINTS {
  VEHICLES = 'vehicles',
  TYPES = 'types',
  MODELS = 'models'
}

export enum VEHICLES_REST_ENDPOINTS {
  FILE = 'file',
  WITHDRAWAL_PROTOCOL = 'withdrawal-protocol',
  EXPORT = 'export'
}
