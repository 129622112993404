import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { REST_ENDPOINTS, PLUGIN_NAME, VEHICLES_REST_ENDPOINTS } from 'plugin-vehicles/shared/constants'
import { VehicleCreateDTO } from 'plugin-vehicles/shared/dto/VehicleCreateDTO'
import { VehicleResponseDTO } from 'plugin-vehicles/shared/dto/VehicleResponseDTO'
import { VehicleResumeResponseDTO } from 'plugin-vehicles/shared/dto/VehicleResumeResponseDTO'
import { VehicleUpdateDTO } from 'plugin-vehicles/shared/dto/VehicleUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.VEHICLES}`
const FILE_PATH = VEHICLES_REST_ENDPOINTS.FILE
const WITHDRAWAL_PROTOCOL = VEHICLES_REST_ENDPOINTS.WITHDRAWAL_PROTOCOL

export async function hasVehicleFile(id: string, fileType: string): Promise<boolean> {
  try {
    const { status } = await api.head(`/${ENDPOINT}/${id}/${FILE_PATH}/${fileType}`)

    return status === 200
  } catch (error) {
    return false
  }
}

/* <================================================[ FILE SERVICE ]================================================> */

export async function getVehicleFile(id: string, fileType: string): Promise<File> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}/${FILE_PATH}/${fileType}`, { responseType: 'blob' })

  if (response instanceof Blob) {
    return new File([response], `${id}__${fileType}.pdf`, { type: response.type })
  }
}

export async function uploadVehicleFile(id: string, fileType: string, data: FormData): Promise<void> {
  await api.post(`/${ENDPOINT}/${id}/${FILE_PATH}/${fileType}`, data)
}

export async function deleteVehicleFile(id: string, fileType: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}/${FILE_PATH}/${fileType}`)
}

/* <==============================================[ END FILE SERVICE ]==============================================> */

export interface WithdrawalProtocolBody {
  name: string
  taxId: string
}

export async function generateWithdrawalProtocol(id: string, body: WithdrawalProtocolBody): Promise<Blob> {
  const { data: response } = await api.post(`/${ENDPOINT}/${id}/${WITHDRAWAL_PROTOCOL}`, body, { responseType: 'blob' })

  if (response instanceof Blob) {
    return new File([response], `${id}.pdf`, { type: response.type })
  }
}

export async function exportVehiclesList(fileType: 'csv' | 'excel' | 'pdf', params: ListClientsFilters): Promise<File> {
  const { data: response } = await api.get(`/${ENDPOINT}/${VEHICLES_REST_ENDPOINTS.EXPORT}/${fileType}`, {
    params,
    responseType: 'blob'
  })

  const exts = {
    csv: 'csv',
    pdf: 'pdf',
    excel: 'xlsx'
  }

  return new File([response], `vehicles.${exts[fileType]}`, { type: response.type })
}

export async function findVehicle(id: string): Promise<VehicleResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listVehicles(params?: ListVehiclesFilters): Promise<PaginationDTO<VehicleResumeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createVehicle(data: VehicleCreateDTO): Promise<VehicleResumeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateVehicle(id: string, data: VehicleUpdateDTO): Promise<VehicleResumeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteVehicle(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
