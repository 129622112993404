import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-vehicles/shared/constants'
import { VehicleModelCreateDTO } from 'plugin-vehicles/shared/dto/VehicleModelCreateDTO'
import { VehicleModelResponseDTO } from 'plugin-vehicles/shared/dto/VehicleModelResponseDTO'
import { VehicleModelUpdateDTO } from 'plugin-vehicles/shared/dto/VehicleModelUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.MODELS}`

export async function findVehicleModel(id: string): Promise<VehicleModelResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listVehicleModels(params?: ListVehiclesFilters): Promise<PaginationDTO<VehicleModelResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createVehicleModel(data: VehicleModelCreateDTO): Promise<VehicleModelResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateVehicleModel(id: string, data: VehicleModelUpdateDTO): Promise<VehicleModelResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteVehicleModel(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
