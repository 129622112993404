import { transparentize } from 'polished'
import styled from 'styled-components'

export const LockedContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => transparentize(0.2, theme['gray-500'])};
  z-index: 99;
`
