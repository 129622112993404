import React from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { filesize } from 'filesize'

import { AutoCompleteHookForm } from 'methone/components/AutoComplete'
import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { SaveButton } from 'methone/components/SaveButton'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormInput, HookFormSelect, HookFormTextarea, Input, TaxIdInput } from 'methone/components/ui/Forms'
import { useSelector } from 'methone/hooks'
import { globalNotification } from 'methone/services/globalNotification'
import { i18n } from 'methone/services/i18n'
import { validatePermissions } from 'methone/shared/validatePermissions'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'

import { FileData, FileItem } from 'plugin-vehicles/components/FileItem'
import { Locked } from 'plugin-vehicles/components/Locked'
import { createVehicleModel, listVehicleModels } from 'plugin-vehicles/services/vehicleModels'
import * as vehicleServices from 'plugin-vehicles/services/vehicles'
import { createVehicleType, listVehicleTypes } from 'plugin-vehicles/services/vehicleTypes'
import { VehicleResponseDTO } from 'plugin-vehicles/shared/dto/VehicleResponseDTO'
import { VehicleUpdateDTO } from 'plugin-vehicles/shared/dto/VehicleUpdateDTO'
import { VehiclePermissions } from 'plugin-vehicles/shared/permissions'
import { docTypes, MENU_AREA, MENU_PATHS } from 'plugin-vehicles/utils/constants'
import { toOption } from 'plugin-vehicles/utils/toOption'

import { EditorContainer, EditorFieldset, WithdrawalModalBody } from './styled'

// eslint-disable-next-line prettier/prettier
type CommonOmit = 'id' | 'clientId' | 'typeId'| 'modelId' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'client' | 'type' | 'model' | 'docType'
interface FormProps extends Omit<VehicleResponseDTO, CommonOmit> {
  client: Option
  type: Option
  model: Option
  docType: Option
}

const defaultValues: FormProps = {
  plate: '',
  renavam: '',
  crv: '',
  active: false,
  docType: null,
  details: '',
  client: null,
  type: null,
  model: null
}

export function VehiclesEditorPage(): JSX.Element {
  const { id } = useParams()

  const { control, handleSubmit, reset, setValue, watch } = useForm<FormProps>({ defaultValues, mode: 'all' })
  const active = watch('active')

  const [loading, setLoading] = React.useState(true)
  const [withdrawalProtocolModal, setWithdrawalProtocolModal] = React.useState(false)
  const [willDownloadProtocol, setWillDownloadProtocol] = React.useState(false)
  const [withdrawalProtocolData, setWithdrawalProtocolData] = React.useState({ name: '', taxId: '' })
  const [crlveFile, setCrlveFile] = React.useState<File>()
  const [atpveFile, setAtpveFile] = React.useState<File>()
  const [withdrawalFile, setWithdrawalFile] = React.useState<File>()
  const fileWindow = React.useRef<Window>()

  const navigate = useNavigate()
  const user = useSelector((state) => state.authentication.user)

  function fileToFileData(file: File, name: string): FileData {
    if (file == null || !(file instanceof File)) {
      return null
    }

    return { name, extra: `${filesize(file.size)}` }
  }

  async function handleCreateVehicleModel(name: string): Promise<void> {
    try {
      setLoading(true)
      const data = await createVehicleModel({ name })
      setValue('model', { value: data.id, label: data.name })
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleCreateVehicleType(name: string): Promise<void> {
    try {
      setLoading(true)
      const data = await createVehicleType({ name })
      setValue('type', { value: data.id, label: data.name })
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  /* <===============================================[ FILE ACTIONS ]===============================================> */

  function handleViewFile(fileType: string): void {
    try {
      setLoading(true)

      const file = fileType === 'crlve' ? crlveFile : fileType === 'atpve' ? atpveFile : withdrawalFile

      if (file == null) {
        return
      }

      const url = URL.createObjectURL(file)
      if (fileWindow.current != null) {
        fileWindow.current.close()
      }

      fileWindow.current = window.open(url, '_blank')

      if (fileWindow.current) {
        fileWindow.current.onclose = () => URL.revokeObjectURL(url)
      } else {
        globalNotification.error(i18n('An error occurred while opening the file window.'))
      }
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleGetFile(fileType: string): Promise<File> {
    try {
      setLoading(true)

      const hasFile = await vehicleServices.hasVehicleFile(id, fileType)

      if (!hasFile) {
        return null
      }

      return vehicleServices.getVehicleFile(id, fileType)
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  function handleUpdateFile(fileType: string) {
    return async function <T extends File>(files: T[]): Promise<void> {
      try {
        setLoading(true)
        const data = new FormData()
        data.append('file', files[0], `${id}__${fileType}.pdf`)
        await vehicleServices.uploadVehicleFile(id, fileType, data)

        if (fileType === 'crlve') {
          setCrlveFile(await handleGetFile('crlve'))
        } else if (fileType === 'atpve') {
          setAtpveFile(await handleGetFile('atpve'))
        } else {
          setWithdrawalFile(await handleGetFile('withdrawal'))
        }
      } catch (error) {
        handleHTTPRequestError(error)
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleDeleteFile(fileType: string): Promise<void> {
    try {
      setLoading(true)
      await vehicleServices.deleteVehicleFile(id, fileType)

      if (fileType === 'crlve') {
        setCrlveFile(null)
      } else if (fileType === 'atpve') {
        setAtpveFile(null)
      } else {
        setWithdrawalFile(null)
      }
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  /* <=============================================[ END FILE ACTIONS ]=============================================> */

  async function handleGetVehicleData(): Promise<void> {
    try {
      setLoading(true)

      if (id === 'new') {
        reset(defaultValues)
      } else {
        const response = await vehicleServices.findVehicle(id)
        reset({
          plate: response.plate,
          renavam: response.renavam,
          crv: response.crv,
          active: response.active,
          docType: docTypes.find((e) => e.value === response.docType),
          details: response.details,
          client: { value: response.client.id, label: `${response.client.name} (${response.client.taxId})` },
          type: { value: response.type.id, label: response.type.name },
          model: { value: response.model.id, label: response.model.name }
        })

        setCrlveFile(await handleGetFile('crlve'))
        setAtpveFile(await handleGetFile('atpve'))
        setWithdrawalFile(await handleGetFile('withdrawal'))
      }

      setLoading(false)
    } catch (e) {
      handleHTTPRequestError(e)
    }
  }

  async function handleSubmitForm(data: FormProps): Promise<void> {
    try {
      setLoading(true)

      const bodyData: VehicleUpdateDTO = {
        clientId: data.client.value,
        plate: data.plate.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
        renavam: data.renavam,
        crv: data.crv,
        typeId: data.type.value,
        modelId: data.model.value,
        active: data.active,
        docType: data.docType.value,
        details: data.details
      }

      if (id === 'new') {
        delete bodyData.active
        const createdUser = await vehicleServices.createVehicle(bodyData)
        navigate(`/${MENU_AREA}/${MENU_PATHS.VEHICLES}/${createdUser.id}`)
      } else {
        await vehicleServices.updateVehicle(id, bodyData)
        await handleGetVehicleData()
      }
    } catch (e) {
      handleHTTPRequestError(e)
    } finally {
      setLoading(false)
    }
  }

  async function handleToggleVehicleState(): Promise<void> {
    try {
      setLoading(true)
      const data = await vehicleServices.findVehicle(id)
      const bodyData = {
        clientId: data.clientId,
        plate: data.plate.replace(/[^a-zA-Z0-9]/g, '').toUpperCase(),
        renavam: data.renavam,
        crv: data.crv,
        typeId: data.typeId,
        modelId: data.modelId,
        active: !data.active,
        docType: data.docType,
        details: data.details
      }
      await vehicleServices.updateVehicle(id, bodyData)
      await handleGetVehicleData()
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setLoading(false)
    }
  }

  async function openWithdrawalProtocol(): Promise<void> {
    let url: string = null
    try {
      setLoading(true)
      const file = await vehicleServices.generateWithdrawalProtocol(id, withdrawalProtocolData)
      url = URL.createObjectURL(file)

      const link = document.createElement('a')
      link.href = url
      link.download = 'Protocolo de Retirada de Documentos'
      link.click()

      await handleToggleVehicleState()
    } catch (error) {
      handleHTTPRequestError(error)
    } finally {
      setWithdrawalProtocolModal(false)

      if (url != null) {
        URL.revokeObjectURL(url)
      }
    }
  }

  function onWithdrawalClick(): void {
    if (active) {
      setWithdrawalProtocolModal(true)
    } else {
      handleToggleVehicleState()
    }
  }

  React.useEffect(() => {
    if (id) {
      handleGetVehicleData()
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FixedLoading position="absolute" enabled={loading} />
      <EditorContainer>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <EditorFieldset>
            <Card className="form-fields">
              <Card.Body>
                <div className="form-row">
                  <ClientSelectorHookForm
                    control={control}
                    name="client"
                    rules={{ required: true }}
                    label={i18n('Client')}
                  />
                </div>

                <div className="form-row">
                  <HookFormInput
                    control={control}
                    rules={{ required: true }}
                    inputProps={{ mask: '@@@-&_&&', replacement: { '&': /[0-9]/, '@': /[A-Za-z]/, _: /[A-Za-z0-9]/ } }}
                    name="plate"
                    label={i18n('Plate')}
                  />
                  <HookFormInput
                    control={control}
                    rules={{ required: true }}
                    inputProps={{ mask: '___________', replacement: { _: /\d/ } }}
                    name="renavam"
                    label={i18n('Renavam')}
                  />
                  <HookFormInput
                    control={control}
                    inputProps={{ mask: '____________', replacement: { _: /\d/ } }}
                    name="crv"
                    label={i18n('CRV')}
                  />
                </div>

                <div className="form-row">
                  <AutoCompleteHookForm
                    control={control}
                    name="type"
                    rules={{ required: true }}
                    label={i18n('Type')}
                    searchFunction={() => listVehicleTypes().then(toOption)}
                    selectProps={{
                      onCreateOption: handleCreateVehicleType,
                      placeholder: '',
                      noOptionsMessage: () => i18n('No types found'),
                      formatCreateLabel: (v) => (
                        <>
                          {i18n('Create')} <strong>{v}</strong>
                        </>
                      )
                    }}
                  />
                  <AutoCompleteHookForm
                    control={control}
                    name="model"
                    rules={{ required: true }}
                    label={i18n('Brand/Model')}
                    searchFunction={() => listVehicleModels().then(toOption)}
                    selectProps={{
                      onCreateOption: handleCreateVehicleModel,
                      placeholder: '',
                      noOptionsMessage: () => i18n('No models found'),
                      formatCreateLabel: (v) => (
                        <>
                          {i18n('Create')} <strong>{v}</strong>
                        </>
                      )
                    }}
                  />
                  <HookFormSelect
                    control={control}
                    name="docType"
                    rules={{ required: true }}
                    options={docTypes}
                    label={i18n('Doc type')}
                  />
                </div>

                <div className="form-row">
                  <HookFormTextarea
                    control={control}
                    name="details"
                    label={i18n('Details')}
                    textareaProps={{ rows: 1 }}
                  />
                </div>
              </Card.Body>
            </Card>
            <div className="aside">
              <Card className="files">
                <Card.Header>
                  <Card.Title>{i18n('Files')}</Card.Title>
                </Card.Header>
                <Card.Body>
                  {id === 'new' && <Locked style={{ borderRadius: 'var(--spacing-1)' }} />}
                  <FileItem
                    fileData={fileToFileData(crlveFile, 'CRLV-e')}
                    idleMessage="CRLV-e"
                    onClick={() => handleViewFile('crlve')}
                    onDelete={() => handleDeleteFile('crlve')}
                    onDropAccepted={handleUpdateFile('crlve')}
                    showDelete={validatePermissions(user.permissions, [VehiclePermissions.VEHICLES_ADMIN])}
                  />
                  <FileItem
                    fileData={fileToFileData(atpveFile, 'ATPV-e')}
                    idleMessage="ATPV-e"
                    onClick={() => handleViewFile('atpve')}
                    onDelete={() => handleDeleteFile('atpve')}
                    onDropAccepted={handleUpdateFile('atpve')}
                    showDelete={validatePermissions(user.permissions, [VehiclePermissions.VEHICLES_ADMIN])}
                  />
                  <FileItem
                    fileData={fileToFileData(withdrawalFile, i18n('Withdrawal'))}
                    idleMessage={i18n('Withdrawal')}
                    onClick={() => handleViewFile('withdrawal')}
                    onDelete={() => handleDeleteFile('withdrawal')}
                    onDropAccepted={handleUpdateFile('withdrawal')}
                    showDelete={validatePermissions(user.permissions, [VehiclePermissions.VEHICLES_ADMIN])}
                  />
                </Card.Body>
              </Card>

              {id !== 'new' && (
                <Card className="danger-zone">
                  <Card.Body>
                    <Button type="button" variant="outline-danger" onClick={onWithdrawalClick}>
                      {i18n(active ? 'Withdraw' : 'Reactive')}
                    </Button>
                  </Card.Body>
                </Card>
              )}
            </div>
          </EditorFieldset>
          <Card className="actions-footer">
            <Card.Body>
              <div className="actions-area">
                <Button type="submit" variant="primary">
                  {i18n(id === 'new' ? 'Create' : 'Save')}
                </Button>
                <span>{i18n('or')}</span>
                <Button type="button" variant="link" onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.VEHICLES}`)}>
                  {i18n('cancel')}
                </Button>
              </div>

              <div className="required-warn">* {i18n('Required fields')}</div>
            </Card.Body>
          </Card>
        </form>
      </EditorContainer>
      <Modal show={withdrawalProtocolModal}>
        <Modal.Header>
          <Modal.Title>{i18n('Withdrawal protocol')}</Modal.Title>
        </Modal.Header>
        <WithdrawalModalBody>
          {!willDownloadProtocol ? (
            <>
              <div className="message">
                {i18n(
                  'To complete the vehicle withdraw, fill out and submit the document withdrawal protocol or the ATPV-e.'
                )}
              </div>
              <div className="actions">
                <Button onClick={() => setWillDownloadProtocol(true)}>{i18n('Download the protocol')}</Button>
                <Button variant="outline-success" onClick={handleToggleVehicleState}>
                  {i18n("I'll send the ATPV-e")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="withdrawal-protocol-form">
                <Input
                  label={i18n('Requester name')}
                  value={withdrawalProtocolData.name}
                  onChange={(e) => {
                    e.persist()
                    setWithdrawalProtocolData((old) => ({ ...old, name: e.target.value }))
                  }}
                />
                <TaxIdInput
                  label={i18n('Requester tax id')}
                  value={withdrawalProtocolData.taxId}
                  onChange={(e) => {
                    e.persist()
                    setWithdrawalProtocolData((old) => ({ ...old, taxId: e.target.value }))
                  }}
                />
                <SaveButton
                  disabled={
                    withdrawalProtocolData == null ||
                    withdrawalProtocolData.name == null ||
                    withdrawalProtocolData.name.trim() === '' ||
                    withdrawalProtocolData.taxId == null ||
                    withdrawalProtocolData.taxId.trim() === ''
                  }
                  onClick={openWithdrawalProtocol}
                  customIdleContent={i18n('Get withdrawal protocol')}
                  customSavingContent={i18n('Loading...')}
                  customSavedContent={i18n('Ready')}
                />
              </div>
            </>
          )}
        </WithdrawalModalBody>
      </Modal>
    </>
  )
}
