import { PaginationDTO } from 'methone/shared/dto/PaginationDTO'
import { api } from 'methone/utils/api'

import { PLUGIN_NAME, REST_ENDPOINTS } from 'plugin-vehicles/shared/constants'
import { VehicleTypeCreateDTO } from 'plugin-vehicles/shared/dto/VehicleTypeCreateDTO'
import { VehicleTypeResponseDTO } from 'plugin-vehicles/shared/dto/VehicleTypeResponseDTO'
import { VehicleTypeUpdateDTO } from 'plugin-vehicles/shared/dto/VehicleTypeUpdateDTO'

const ENDPOINT = `${PLUGIN_NAME}/${REST_ENDPOINTS.TYPES}`

export async function findVehicleType(id: string): Promise<VehicleTypeResponseDTO> {
  const { data: response } = await api.get(`/${ENDPOINT}/${id}`)

  return response
}

export async function listVehicleTypes(params?: ListVehiclesFilters): Promise<PaginationDTO<VehicleTypeResponseDTO>> {
  const { data: response } = await api.get(`/${ENDPOINT}`, { params })

  return response
}

export async function createVehicleType(data: VehicleTypeCreateDTO): Promise<VehicleTypeResponseDTO> {
  const { data: response } = await api.post(`/${ENDPOINT}`, data)

  return response
}

export async function updateVehicleType(id: string, data: VehicleTypeUpdateDTO): Promise<VehicleTypeResponseDTO> {
  const { data: response } = await api.put(`/${ENDPOINT}/${id}`, data)

  return response
}

export async function deleteVehicleType(id: string): Promise<void> {
  await api.delete(`/${ENDPOINT}/${id}`)
}
