import { i18n } from 'methone/services/i18n'
import { menuService } from 'methone/services/menuService'
import { routerService } from 'methone/services/routerService'

import { VehiclePermissions } from 'plugin-vehicles/shared/permissions'

import { enUS, ptBR } from './i18n'
import { VehicleModelsEditorPage, VehicleModelsPage } from './pages/VehicleModels'
import { VehiclesEditorPage, VehiclesPage } from './pages/Vehicles'
import { VehicleTypesEditorPage, VehicleTypesPage } from './pages/VehicleTypes'
import { MENU_AREA, MENU_PATHS } from './utils/constants'

i18n.addItems('pt-BR', ptBR.values)
i18n.addItems('en-US', enUS.values)

const permissions = [VehiclePermissions.VEHICLES_MANAGER, VehiclePermissions.VEHICLES_ADMIN]

menuService.addItem({ basePath: `/${MENU_AREA}`, title: i18n('Vehicle broker'), icon: 'fas fa-car', permissions, order: 1 }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.VEHICLES}`, title: i18n('Vehicles'), order: 1, element: VehiclesPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.VEHICLES}/:id`, title: i18n('Vehicles'), element: VehiclesEditorPage, permissions }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.MODELS}`, title: i18n('Brand/Models'), order: 2, element: VehicleModelsPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.MODELS}/:id`, title: i18n('Brand/Models'), element: VehicleModelsEditorPage, permissions }) // eslint-disable-line prettier/prettier

menuService.addSubItem(`/${MENU_AREA}`, { path: `/${MENU_PATHS.TYPES}`, title: i18n('Types'), order: 3, element: VehicleTypesPage }) // eslint-disable-line prettier/prettier
routerService.addRoute({ path: `/${MENU_AREA}/${MENU_PATHS.TYPES}/:id`, title: i18n('Types'), element: VehicleTypesEditorPage, permissions }) // eslint-disable-line prettier/prettier
