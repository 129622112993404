import React from 'react'
import { Badge, Button, Card, Dropdown } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { useRequest } from 'ahooks'

import { ClientSelectorHookForm } from 'methone/components/ClientSelector'
import { FixedLoading } from 'methone/components/ui/FixedLoading'
import { HookFormSelect, HookFormInput } from 'methone/components/ui/Forms'
import { Pagination } from 'methone/components/ui/Pagination'
import { SortedTable, SortedTableSort } from 'methone/components/ui/SortedTable'
import { useQueryParams } from 'methone/hooks'
import { globalConfirmation } from 'methone/services/globalConfirmation'
import { i18n } from 'methone/services/i18n'
import { findClientResume, listClientGroups } from 'methone/services/rest/clients'
import { handleHTTPRequestError } from 'methone/utils/handleHTTPRequestError'
import { validatePermsByStore } from 'methone/utils/validatePermsByStore'

import { listVehicleModels } from 'plugin-vehicles/services/vehicleModels'
import { deleteVehicle, exportVehiclesList, listVehicles } from 'plugin-vehicles/services/vehicles'
import { listVehicleTypes } from 'plugin-vehicles/services/vehicleTypes'
import { VehiclePermissions } from 'plugin-vehicles/shared/permissions'
import { docTypes, MENU_AREA, MENU_PATHS, vehicleStatus } from 'plugin-vehicles/utils/constants'
import { toOption } from 'plugin-vehicles/utils/toOption'

import { Form, SolidDot, VehiclesContainer } from './styled'

type Omits = 'clientId' | 'types' | 'models' | 'groups' | 'platesEnd' | 'docTypes' | 'status'
interface FormData extends Omit<ListVehiclesFilters, Omits> {
  client: Option<string>
  groups: Options<string>
  types: Options<string>
  models: Options<string>
  platesEnd: Options<string>
  docTypes: Options<string>
  status: Options<string>
}

const platesEnd: Options = Array.from({ length: 10 }).map((_, i) => ({ value: `${i}`, label: `${i}` }))

const defaultValues: FormData = {
  page: 1,
  perPage: 10,
  client: null,
  crv: '',
  docTypes: [],
  groups: [],
  platesEnd: [],
  types: [],
  models: [],
  plate: '',
  renavam: '',
  status: vehicleStatus.filter((i) => i.value === 'true')
}

const bodyCellStyle: React.CSSProperties = { justifyContent: 'center' }

export function VehiclesPage(): JSX.Element {
  const [params, setParams] = useQueryParams<ListVehiclesFilters>({ page: 1, perPage: 10, status: ['true'] })
  const { control, handleSubmit, reset } = useForm<FormData>({ defaultValues })
  const [vehicleTypes, setVehicleTypes] = React.useState<Options<string>>([])
  const [vehicleModels, setVehicleModels] = React.useState<Options<string>>([])
  const [clientGroups, setClientGroups] = React.useState<Options<string>>([])

  const { loading: vehiclesLoading, mutate, run, data, error } = useRequest(listVehicles, { manual: true })
  const [loading, setLoading] = React.useState(false)
  const navigate = useNavigate()

  function handleDownloadFile(fileType: 'csv' | 'excel' | 'pdf') {
    return async () => {
      let url: string = null
      try {
        setLoading(true)
        const file = await exportVehiclesList(fileType, params)
        url = URL.createObjectURL(file)

        const link = document.createElement('a')
        link.href = url
        link.download = file.name
        link.click()
      } catch (error) {
        handleHTTPRequestError(error)
      } finally {
        setLoading(false)

        if (url != null) {
          URL.revokeObjectURL(url)
        }
      }
    }
  }

  async function handleDeleteVehicle({ id, plate: key }: { id: string; plate: string }): Promise<void> {
    try {
      setLoading(true)

      if (await globalConfirmation.danger(i18n('Are you sure you want to delete the vehicle "{key}"?', { key }))) {
        await deleteVehicle(id)
        await mutate((data) => ({ ...data, entries: data.entries.filter((user) => user.id !== id) }))
      }
    } catch (err) {
      handleHTTPRequestError(err)
    } finally {
      setLoading(false)
    }
  }

  function onSubmit(fields: FormData, _e: React.BaseSyntheticEvent<object, any, any>): void {
    setParams({
      page: params.page,
      perPage: params.perPage,
      orderBy: params.orderBy,
      direction: params.direction,
      clientId: fields.client?.value,
      crv: fields.crv,
      docTypes: fields.docTypes?.map((docType) => docType.value),
      models: fields.models?.map((model) => model.value),
      plate: fields.plate?.replace(/[^a-zA-Z0-9]/g, '')?.toUpperCase(),
      renavam: fields.renavam,
      types: fields.types?.map((type) => type.value),
      groups: fields.groups?.map((group) => group.value),
      platesEnd: fields.platesEnd?.map((ped) => ped.value.replace(/[^0-9]/g, ''))
    })
  }

  async function updateFormData(): Promise<void> {
    try {
      const client = params.clientId ? await findClientResume(params.clientId) : null

      const localVehicleTypes = await listVehicleTypes().then(toOption)
      setVehicleTypes(localVehicleTypes)

      const localVehicleModels = await listVehicleModels().then(toOption)
      setVehicleModels(localVehicleModels)

      const localClientGroups = await listClientGroups()
      setClientGroups(localClientGroups)

      reset({
        client: client ? { value: client.id, label: `${client.name} (${client.taxId})` } : null,
        crv: params.crv,
        direction: params.direction,
        groups: toOption(params.groups ?? []),
        orderBy: params.orderBy,
        page: params.page,
        perPage: params.perPage,
        plate: params.plate,
        platesEnd: toOption(params.platesEnd ?? []),
        renavam: params.renavam,
        models: params.models?.map((i) => localVehicleModels.find((t) => t.value === i)) ?? [],
        types: params.types?.map((i) => localVehicleTypes.find((t) => t.value === i)) ?? [],
        status: params.status?.map((i) => vehicleStatus.find((t) => t.value === i)) ?? []
      })
    } catch (err) {
      handleHTTPRequestError(err)
    }
  }

  React.useEffect(() => {
    if (error) {
      handleHTTPRequestError(error)
    }
  }, [error])

  React.useEffect(() => {
    run(params)
    updateFormData()
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VehiclesContainer>
      <Card style={{ marginBottom: 'var(--spacing-2)' }}>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ClientSelectorHookForm control={control} label={i18n('Client')} name="client" />
            <HookFormSelect control={control} label={i18n('Groups')} name="groups" isMulti options={clientGroups} />

            <HookFormInput
              control={control}
              inputProps={{ mask: '@@@-&_&&', replacement: { '&': /[0-9]/, '@': /[A-Za-z]/, _: /[A-Za-z0-9]/ } }}
              label={i18n('Plate')}
              name="plate"
            />
            <HookFormSelect control={control} label={i18n('Plate end')} name="platesEnd" isMulti options={platesEnd} />
            <HookFormSelect control={control} label={i18n('Doc type')} name="docTypes" isMulti options={docTypes} />
            <div className="renavam-crv-container">
              <HookFormInput
                control={control}
                inputProps={{ mask: '___________', replacement: { _: /\d/ } }}
                label={i18n('Renavam')}
                name="renavam"
              />
              <HookFormInput
                control={control}
                inputProps={{ mask: '____________', replacement: { _: /\d/ } }}
                label={i18n('CRV')}
                name="crv"
              />
            </div>

            <div className="types-model-container">
              <HookFormSelect control={control} label={i18n('Type')} name="types" isMulti options={vehicleTypes} />
              <HookFormSelect
                control={control}
                label={i18n('Brand/Model')}
                name="models"
                isMulti
                options={vehicleModels}
              />
              <HookFormSelect control={control} label={i18n('Status')} name="status" isMulti options={vehicleStatus} />
            </div>

            <div className="actions">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary" className="export-button">
                  <i className="fas fa-download" />
                  {i18n('Export')}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleDownloadFile('csv')}>
                    <i className="fas fa-file-csv" />
                    {i18n('CSV')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('excel')}>
                    <i className="fas fa-file-excel" />
                    {i18n('Excel')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDownloadFile('pdf')}>
                    <i className="fas fa-file-pdf" />
                    {i18n('PDF')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button type="submit" variant="secondary">
                <i className="fas fa-filter" />
                {i18n('Apply filters')}
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={() => navigate(`/${MENU_AREA}/${MENU_PATHS.VEHICLES}/new`)}
              >
                <i className="fas fa-plus" />
                {i18n('New vehicle')}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <SortedTable
        items={(data?.entries ?? [])?.map(({ client, ...vehicle }) => ({
          id: vehicle.id,
          status: <SolidDot title={i18n(vehicle.active ? 'Active' : 'Inactive')} status={vehicle.active} />,
          client: (
            <div>
              {client.group && (
                <Badge bg="outline-secondary" style={{ marginRight: 'var(--spacing-2)' }}>
                  {client.group}
                </Badge>
              )}
              <span>{client.name}</span>
            </div>
          ),
          plate: vehicle.plate,
          type: vehicle.type.name,
          model: vehicle.model.name,
          docType: vehicle.docType.toUpperCase().replace('CRLVE', 'CRLV-e')
        }))}
        columns={[
          { bodyCellStyle, title: '', columnRef: 'status', sortable: false, width: 45 },
          { title: i18n('Client'), columnRef: 'client', sortable: true },
          { title: i18n('Plate'), columnRef: 'plate', sortable: true, width: 90 },
          { title: i18n('Type'), columnRef: 'type', sortable: true, width: 150 },
          { title: i18n('Brand/Model'), columnRef: 'model', sortable: true },
          { title: i18n('Doc type'), columnRef: 'docType', sortable: true, width: 150 }
        ]}
        sort={{
          column: params.orderBy,
          direction: params.direction as SortedTableSort,
          onSort: (orderBy, direction) => setParams({ ...params, orderBy, direction })
        }}
        actions={[
          {
            icon: 'far fa-edit',
            onClick: ({ id }) => navigate(`/${MENU_AREA}/${MENU_PATHS.VEHICLES}/${id}`)
          },
          {
            icon: 'far fa-trash-alt',
            onClick: ({ id, plate }) => handleDeleteVehicle({ id, plate }),
            hide: !validatePermsByStore([VehiclePermissions.VEHICLES_ADMIN])
          }
        ]}
      />
      <div style={{ paddingTop: 'var(--spacing-2)' }}>
        <Pagination
          currentPage={Number(params.page)}
          onChangePage={(page) => setParams({ ...params, page })}
          totalPages={Number(data?.totalPages ?? 1)}
        />
      </div>
      <FixedLoading enabled={vehiclesLoading || loading} />
    </VehiclesContainer>
  )
}
